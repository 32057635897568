
<template>
  <div class="login-page">
    <div class="login-wraper">
      <img src="@/assets/img/login.png"  />
      <router-view></router-view>
    </div>
    <Copyright></Copyright>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
  background: url('../../assets/img/bg.png') center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .login-wraper{
    display: flex;
    height: 469px;
    margin-bottom: 100px;
    overflow: hidden;
  }
   @media (max-height: 800px) {
   .login-wraper{
     margin: 50px 0;
   }
 }
}
</style>
